import React from 'react';
import { Helmet } from 'react-helmet';

const Contact = () => (
  <div>
    <Helmet>
      <title>Contact - Software Services</title>
    </Helmet>
    <h1>Contact Us</h1>
    <p>Email: info@softwareservices.com</p>
  </div>
);

export default Contact;
