import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/home/Home';
import Services from './components/services/Services';
import Blog from './components/blog/Blog';
import NewPost from './components/NewPost';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Article1 from './components/articles/article1/Article1';
import Article2 from './components/Article2';

const App = () => (
  <Router>
    <div id="root">
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/new-post" element={<NewPost />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/articles/article-1" element={<Article1 />} />
          <Route path="/articles/article-2" element={<Article2 />} />
        </Routes>
      </main>
      <Footer />
    </div>
  </Router>
);

export default App;
