import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Blog.css'; // Make sure to import the CSS file

const Blog = () => {
  return (
    <div className='blog-container'>
      <Helmet>
        <title>Blog - Software Services</title>
      </Helmet>
      <h1>Blog</h1>

      <div className='article'>
        <h2><Link to="/articles/article-1">The Future of REST APIs: Trends to Watch</Link></h2>
        <p>The evolution of REST APIs has been a cornerstone of modern web development. As we look to the future, several trends are poised to redefine how APIs are designed, implemented, and utilized.</p>
      </div>
      {/* <div className='article'>
        <h2><Link to="/articles/article-2">Article 2 Title</Link></h2>
        <p>Short description of Article 2...</p>
      </div> */}
    </div>
  );
};

export default Blog;
