import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NewPost = ({ client }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await client.connect();
    const database = client.db("blogDB");
    const collection = database.collection("posts");
    await collection.insertOne({ title, content });
    client.close();
    setTitle('');
    setContent('');
    navigate('/blog');  // Redirect to blog page after adding the post
  };

  return (
    <div>
      <h1>Add New Post</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title</label>
          <input 
            type="text" 
            value={title} 
            onChange={(e) => setTitle(e.target.value)} 
            required 
          />
        </div>
        <div>
          <label>Content</label>
          <textarea 
            value={content} 
            onChange={(e) => setContent(e.target.value)} 
            required 
          />
        </div>
        <button type="submit">Add Post</button>
      </form>
    </div>
  );
};

export default NewPost;
