import React from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';
import { motion } from 'framer-motion';
import bitcoinLogo from '../images/bitcoin.png'; // Ensure you have these images in your project
import tiktokLogo from '../images/tiktok.png';
import nftLogo from '../images/nft.png';
import youtubeLogo from '../images/youtube.png';

const servicesData = [
  {
    category: 'For Innovators & Small Companies',
    services: [
      { name: 'Software Development', price: '$5,000 - $20,000' },
      { name: 'Payment System Integration', price: '$1,500 - $5,000' },
      { name: 'Fundraising Support', price: '$1,000 - $5,000' },
      { name: 'Investor Pitch Decks', price: '$1,000 - $2,500' },
      { name: 'Early User Acquisition Strategy', price: '$1,500 - $5,000' },
    ],
  },
  {
    category: 'For Digital Creators',
    services: [
      { name: 'Custom Art for NFT Collections', price: '$500 - $4,000' },
      { name: 'Social Media Growth Consulting', price: '$500 - $2,000' },
      { name: 'Brand Identity Development', price: '$1,000 - $3,000' },
      { name: 'Website Design & Development', price: '$2,000 - $10,000' },
      { name: 'Activation Funnel Creation & Optimization', price: '$1,500 - $4,000' },
      { name: 'Monetization Strategies', price: '$500 - $2,500' },
    ],
  },
  {
    category: 'For Investors',
    services: [
      { name: 'Automated Trading Development', price: '$5,000 - $15,000' },
      { name: 'Early Startup Showcases', price: '$500 - $2,000/year' },
      { name: 'First Rights in NFT Auctions', price: '$1,000 - $5,000/year' },
    ],
  },
];

const scrollToServices = () => {
  document.getElementById('services-section').scrollIntoView({ behavior: 'smooth' });
};

const Home = () => (
  <>
    <Helmet>
      <title>Creative Hub</title>
    </Helmet>

    <motion.section
      className='hero'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h1>Innovators, Digital Creators, and Investors</h1>
      <h2>Transform Your Ideas into Reality with Cutting-Edge Tech and Artistry</h2>
      <div className="hero-images">
        <img src={bitcoinLogo} alt="Bitcoin Logo" />
        <img src={tiktokLogo} alt="TikTok Logo" />
        <img src={nftLogo} alt="NFT Logo" />
        <img src={youtubeLogo} alt="YouTube Logo" />
      </div>
      <motion.button
        className='cta-button'
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={scrollToServices}
      >
        Explore Our Services
      </motion.button>
    </motion.section>
    
    <section id='services-section' className='section-services'>
      <h2>Our Services</h2>
      <div className='services-table'>
        {servicesData.map((category, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.3 * index }}
            className='service-category'
          >
            <h3>{category.category}</h3>
            <table>
              <thead>
                <tr>
                  <th>Service</th>
                </tr>
              </thead>
              <tbody>
                {category.services.map((service, idx) => (
                  <tr key={idx}>
                    <td>{service.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </motion.div>
        ))}
      </div>
      <motion.a
        className='cta-button'
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        href="https://calendly.com/mario-stumpo/30min"
        target="_blank"
        rel="noopener noreferrer"
      >
        Book a Call
      </motion.a>
    </section>
  </>
);

export default Home;
