import React from 'react';
import { Helmet } from 'react-helmet';

const Article2 = () => {
  return (
    <div>
      <Helmet>
        <title>Article 2 Title</title>
        <meta name="description" content="Description of Article 2" />
      </Helmet>
      <h1>Article 2 Title</h1>
      <p>Content of Article 2...</p>
    </div>
  );
};

export default Article2;
