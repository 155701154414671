import React from 'react';
import { Helmet } from 'react-helmet';
import './Services.css'

const Services = () => (
  <div className='section-home-two'>
    <Helmet>
      <title>Services - Software Services</title>
    </Helmet>
    <h1>Sviluppiamo software di trading dal 2013</h1>
    <p>Ora puoi fare trading utilizzando le stesse tecnologie dei migliori trader al mondo.</p>
    <h3>Vantaggi di un software di trading personalizzato</h3>
    <ul>
      <li>Mantieni tutti i tuoi dati riservati</li>
      <li>Personalizza la tua logica di trading</li>
      <li>Integra servizi di terze parti come Binance e Coinbase per ottenere i migliori risultati nel trading di criptovalute</li>
    </ul>
  </div>
);

export default Services;
